<template>
  <v-snackbar
    transition="scale-transition"
    color="#2ecc71"
    rounded
    top
    elevation="0"
    timeout="5000"
    v-model="isVisible"
  >
    <slot name="snackbarContent"> </slot>
    <template v-slot:action="{ on, attrs }">
      <v-btn
        v-on="on"
        color="white"
        text
        icon
        class="text-capitalize"
        v-bind="attrs"
        @click="isVisible = false"
      >
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    isExternalActivator: {
      type: Boolean,
      required: false,
      default: false,
    },

    // mode: {
    //   type: String,
    //   required: false,
    //   default: "",
    // },
  },

  data: () => ({
    isVisible: false,

    snackbar: {
      msg: "",
    },
  }),

  methods: {},

  mounted() {},
};
</script>

<style lang="scss">
.v-snack {
  .v-snack__wrapper {
    top: 3%;
  }
}
</style>
